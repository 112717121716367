body,
html {
    align-items: flex-start;
    background: #202020;
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-y: hidden;
    padding: 5vh 0 0;
}

.logo-container,
body,
html {
    display: flex;
    justify-content: center;
}

.projects a {
    line-height: 1.75; /* Adjust this value as needed */
}

a, a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover, a:focus {
    color: inherit;
    text-decoration: underline;
}

.logo-container {
    align-items: flex-end;
    font-size: 10em;
    height: 30vh;
    position: relative;
    transition: all 0.75s;
}

@media screen and (max-width: 600px) {
    .logo-container {
        font-size: 5em;
    }
}

.normal-color {
    color: #7f7f7f;
}

.dev,
.hidden,
.letter {
    background-color: #202020;
    transition: all 0.75s;
}

.dev,
.letter {
    color: #7f7f7f;
}

.hidden {
    color: #f2f2f2;
    margin: 0 -0.2em;
    opacity: 0;
}
.dev {
    font-size: 0.5em;
    margin-bottom: 0.3em;
    margin-left: 0.1em;
    opacity: 1;
}
.r-adjust {
    margin-left: -0.15em;
}
.projects,
.title {
    font-size: 0.2em;
}
.name,
.projects,
.social-links,
.title {
    color: #f2f2f2;
    left: 0.15em;
    opacity: 0;
    position: absolute;
}
.name,
.social-links {
    font-size: 0.375em;
}
.name {
    bottom: 0.8em;
    transition: all 0.75s;
}
.title {
    top: 95%;
}
.social-links,
.title {
    transition: opacity 0.75s 1.75s;
}
.social-links {
    display: flex;
    gap: 1.1em;
    justify-content: center;
    top: 115%;
}
.projects {
    top: 150%;
    transition: opacity 0.75s 2.75s;
    width: 100vh;
}
.animate .letter {
    margin: 0;
}
.animate .hidden,
.animate .projects,
.animate .social-links,
.animate .title {
    opacity: 1;
}
.animate .dev {
    opacity: 0;
}
.show-name {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
.project-name,
.social-links i {
    color: #f2f2f2;
    font-size: 1em;
}
.project-title {
    font-size: 1.5em;
    font-weight: 700;
}
a {
    font-weight: 700;
    text-decoration: none;
}
.clipboard-message {
    background-color: #202020;
    border-radius: 0.5em;
    color: #f2f2f2;
    font-size: 0.5em;
    margin-left: -10.6em;
    margin-top: 0.6em;
    opacity: 0;
    padding: 0.2em 0.5em;
    position: absolute;
    transition: opacity 0.4s;
}
.hide-message {
    opacity: 0;
}
.show-message {
    opacity: 1;
}